<template>
  <CRow>
    <!-- <AuthUpdater /> -->
    <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader>
          <CButton color="info" size="sm" @click="itemEditStart" v-show="!is_item_edit">New</CButton>
          <CButton color="warning" size="sm" @click="itemSave" v-show="is_item_edit">Save</CButton>
          <CButton color="light" size="sm" @click="refreshItems" style="margin-left: 20px">Refresh</CButton>
          <CButton color="dark" size="sm" @click="cacheUpdate" style="margin-left: 20px">Cache Refresh</CButton>
          <!-- <p>{{ error_message }}</p> -->
        </CCardHeader>
      </CCard>
    </CCol>
    <!-- <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader>
          <CForm>
            <CFormInput type="email" id="exampleFormControlInput1" label="Email address" placeholder="name@example.com"
              text="Must be 8-20 characters long." aria-describedby="exampleFormControlInputHelpInline" v-model="test_123"/>
          </CForm>
          <p>{{ test_123 }}</p>
        </CCardHeader>
      </CCard>
    </CCol> -->
    <CCol :xs="12" v-if="is_item_edit">
      <CCard class="mb-4">
        <CCardHeader>
          <div v-for="k in editableItem" :key="k.name">

            <input type="text" v-model="k.value" :disabled="k.name === 'id'" style="margin-right: 20px;">{{ k.name }} {{
              k.type ? '(' + k.type + ')' :
              '' }}
            {{ k.ref ? ' ref:' + k.ref.category + '.' + k.ref.column : '' }}
          </div>
        </CCardHeader>
      </CCard>
    </CCol>
    <!-- // -->
    <CCol :xs="12">
      <CCard class="mb-4" v-for="it of items" :key="it.id + '_'">
        <CCardHeader>
          <!-- <CButton color="warning" size="sm" @click="itemSave">Update</CButton> -->
          <CButton color="danger" size="sm" @click="itemDelete(it.id)" style="margin-right: 15px">x</CButton>
          <CButton color="warning" size="sm" @click="itemEditStart(it)" style="margin-right: 40px">edit</CButton>
          <strong style="margin-right: 20px; margin-left: 20px">id: {{ it.id }}</strong>
          <small>{{ it.code || it.name || '' }}</small>
        </CCardHeader>
        <CCardBody>
          <!-- <p class="text-medium-emphasis small">
            Place one add-on or button on either side of an input. You may also
            place one on both sides of an input. Remember to place
            <code>&lt;CFormLabel&gt;</code>s outside the input group.
          </p> -->
          <!-- <CInputGroup class="mb-3" > -->
          <div v-for="prop in Object.keys(it)" :key="prop">
            <input type="text" disabled :value="it[prop]" style="margin-right: 20px;">
            <strong>{{ prop }}</strong>
          </div>
          <!-- <CInputGroupText id="inputGroup-sizing-default">Default</CInputGroupText>
            <CFormInput aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" /> -->
          <!-- </CInputGroup> -->
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { defineComponent } from 'vue'
// import { useState ,mapState} from 'pinia'
import axios from 'axios'
import { computed } from 'vue'
import { useStore } from 'vuex'
import AuthUpdater from '@/components/AuthUpdater.vue'

export default {
  name: 'ListEdit',
  async mounted() {

    await this.refreshItems()
  },
  methods: {
    async sleep(ms) {
      return new Promise((_r, _j) => {
        setTimeout(() => {
          _r()
        }, ms)
      })
    },
    async itemEditStart(editItem) {
      this.is_item_edit = true
      // this.categoryName
      this.editableItem = []
      for (let item of this.metadata[this.categoryName].props) {
        if (!editItem)
          item.value = ''
        else {
          if (editItem[item.name] === null && editItem[item.name] === undefined)
            item.value = ''
          // else if (item.type === 'jsonb')
          //   item.value = JSON.stringify(editItem[item.name])
          else
            item.value = editItem[item.name]
        }
        this.editableItem.push(item)
      }
    },
    async refreshItems() {
      this.is_item_edit = false
      this.editableItem.splice(0, this.editableItem.length)
      this.categoryName = (window.location.href.match(/cat=(.+)$/) || [])[1]
      let at = localStorage.getItem('access_token')
      try {
        let metadata = await axios({
          url: `${this.BASEURL}/api/v1/settings/metadata`,
          method: 'get',
        })
        if (!metadata.data.ok) throw new Error('INVALID_PARAMS')

        this.metadata = metadata.data.data

        let response = await axios({
          url: `${this.BASEURL}/api/v1/settings/${this.categoryName}/`,
          method: 'get',
          headers: {
            Authorization: at
          }
          // data: {
          //   user: this.login_username,
          //   pass: hsd,
          // },
        })
        if (!response.data.ok) throw new Error('INVALID_PARAMS')
        this.items.splice(0, this.items.length)
        for (let item of response.data.data) {
          for (let ii of this.metadata[this.categoryName].props) {
            if (ii.type === 'jsonb') {
              item[ii.name] = JSON.stringify(item[ii.name])
            }
          }
          this.items.push(item)
        }
      } catch (error) {
        console.log(`[${'ListEdit'}:${this.categoryName}] err::${error.message}`)
      }
    },
    async cacheUpdate() {
      try {
        let at = localStorage.getItem('access_token')
        this.is_item_edit = false
        let response = await axios({
          url: `${this.BASEURL}/api/v1/cache/update`,
          method: 'get',
          headers: {
            Authorization: at
          },
        })
        if (!response.data.ok) throw new Error('INVALID_PARAMS')
        await this.refreshItems()
      } catch (e) {
        console.log(`${e.message}`)
        this.error_message = `${e.message}`
        await this.sleep(3000)
        this.error_message = ''
      }
    },
    async itemSave() {
      try {
        let at = localStorage.getItem('access_token')
        this.is_item_edit = false
        let dd = {

        }
        let itemId = null
        for (let i of this.editableItem) {
          if (i.value !== '') {
            dd[i.name] = i.value
            if (i.name === 'id') itemId = i.value
          }
        }
        let response = await axios({
          url: itemId ? `${this.BASEURL}/api/v1/settings/${this.categoryName}/${itemId}` : `${this.BASEURL}/api/v1/settings/${this.categoryName}`,
          method: itemId ? 'patch' : 'post',
          headers: {
            Authorization: at
          },
          data: dd
        })
        if (!response.data.ok) throw new Error('INVALID_PARAMS')
        await this.refreshItems()
      } catch (e) {
        console.log(`${e.message}`)
        this.error_message = `${e.message}`
        await this.sleep(3000)
        this.error_message = ''
      }
    },
    async itemDelete(id) {
      let at = localStorage.getItem('access_token')
      let response = await axios({
        url: `${this.BASEURL}/api/v1/settings/${this.categoryName}/${id}`,
        method: 'delete',
        headers: {
          Authorization: at
        }
      })
      if (!response.data.ok) throw new Error('INVALID_PARAMS')
      await this.refreshItems()
    },
  },
  onInputTestCmp(...args) {
    args
    debugger
  },
  data() {
    return {
      BASEURL: process.env.BASE_API_URL || '',//|| 'http://localhost:8084',
      is_item_edit: false,
      editableItem: [],
      categoryName: '',
      error_message: '',
      test_123: '',
      metadata: {},
      items: [],
    }
  },
  setup() {
    return {}
  },
  components: {
    AuthUpdater,
  }
}
</script>
