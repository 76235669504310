import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/categories',
        name: 'Categories',
        redirect: '/',
        children: [
          ...([
            'Game',
            'GameBets',
            'GameImages',
            'GameLocalisation',
            'GameVersion',
            'BetLevels',
            'Operator',
            'Operator',
            'OperatorBrand',
            'OperatorCurrencies',
            'OperatorGames',
            'OperatorWL',
            'Securitygroup',
          ].filter(i => i).map(i => ({ path: '/categories/' + i, name: 'ListEdit_' + i, component: () => import(`@/views/categories/${i}.vue`), })))
          ,{ path: '/transactions/ShowTrans', name: 'Transactions', component: () => import('@/views/transactions/ShowListTable.vue'), }
          ,{ path: '/transactions/ShowAggs', name: 'Aggregations', component: () => import('@/views/transactions/ShowAggs.vue'), }
          ,{ path: '/pages/create_user', name: 'CreateUser', component: () => import('@/views/account/CreateUser.vue'), }
        ],
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
    ],
  },

  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      }
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  // routes:[{
  //   path: '/',
  //   name: 'Home',
  //   component: MainLay,
  // }],
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
