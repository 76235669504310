<template></template>
<script>
import { defineComponent } from 'vue'
// import { useState ,mapState} from 'pinia'
import axios from 'axios'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  async mounted() {
    const store = useStore()
    // this.auth_token = localStorage.getItem('token')
    // // localStorage.setItem("token", "");
    // let isExpd = parseInt(localStorage.getItem('token_exp')) < Date.now()
    // if (!this.auth_token) this.show_authmodal = true
    // if (this.auth_token && !isExpd) this.show_authmodal = false
    console.log(`[AUTH_UPDATER:mounted]`)
    while (1)
      try {
        let rt = localStorage.getItem('refresh_token')
        let response = await axios({
          url: `${this.BASEURL}/api/v1/auth/refresh`,
          method: 'post',
          data: {
            refresh_token: rt,
          },
        })
        if (!response.data.ok) throw new Error('INVALID_PASSWORD')
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
        // localStorage.setItem('fullauthdata', JSON.stringify(response.data))
        // console.log(`[AUTH_UPDATER:mounted] refresh done `)
        // store.commit('disableAuthShow')
      } catch (error) {
        console.log(`[AUTH_UPDATER:mounted] refresh error:${error.message}`)
        // store.commit('enableAuthShow')
        this.$router.push({ path: '/pages/login' })
        // debugger
        // debugger
        // this.$router.push({ path: '/pages/login' })
      }
      finally {
        await this.sleep(this.refresh_interval)
      }
  },
  methods: {
    async sleep(ms) {
      return new Promise((_r, _j) => {
        setTimeout(() => {
          _r()
        }, ms)
      })
    },
    async sendAuth() {
      this.error_message = ''
      this.login.username
      this.login.password
      console.log(`-- sendAuth`)
      try {
        // localStorage.setItem("token_exp", Date.now() + 600000);
        // // let aa = this.useState("auth_token");
        // // let aa =
        // this.auth_token = response.data.token;
        // // auth_token.tkn = response.data.token;
        // // let na = useNuxtApp();
        // // let nn = useState("auth_token");
        // // nn.tkn = response.data.token;
        // this.show_authmodal = false;
        // console.log(response);
        // return response.data.token;
      } catch (err) {
        // this.show_authmodal = true;
        // this.login.password = "";
        // localStorage.setItem("token", "");
        // this.error_message = "Invalid credentials";
        // setTimeout(() => {
        //   this.error_message = "";
        // }, 3000);
        // // console.log("", err);
      }
    },
  },
  data() {
    // const store = useStore()
    return {
      // is_show_auth: useState('is_show_auth', () => false),
      // refresh_token: useState('refresh_token', () => ''),
      // show_authmodal: useState("show_authmodal", () => false),

      error_message: '',
      BASEURL: process.env.BASE_API_URL || '',//'http://localhost:8084',
      // refresh_interval:60 * 1000 * 3,
      refresh_interval: 15 * 1000,
      login: {
        username: '',
        password: '',
      },
    }
  },
}
</script>


