import { createStore } from 'vuex'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    is_auth_show: false,
  },
  mutations: {
    toggleSidebar(state) {
      //$store.commit('toggleSidebar')
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
      /*
      //import { useStore } from 'vuex'
          @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
      */
    },
    enableAuthShow(state) {
      state.is_auth_show = true
    },
    disableAuthShow(state) {
      state.is_auth_show = false
    },
    toggleAuthShow(state) {
      state.is_auth_show = !state.is_auth_show
    },
  },
  actions: {},
  modules: {},
})
